.popup-container .popup-bubble-anchor .clickable-info-window {
  display: block;
  background: white;
  padding: 8px;
  color: #000000;
  border: 1px solid #1BACE0;
  border-radius: 6px;
  max-height: 200px;
  min-width: 180px;
  max-width: 250px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 4px 10px 0 rgba(0, 0, 0, 0.19);
  z-index: 100;
}

.popup-container .popup-bubble-anchor:has(> .clickable-info-window)::after,
.popup-container .popup-bubble-anchor:has(> .clickable-info-window)::before {
  content: '';
  position: absolute;
  border-width: 6px;
  border-style: solid;
}

.popup-container .popup-bubble-anchor:has(> .clickable-info-window)::after {
  z-index: 101;
  top: calc(100% - 2px);
  border-color: #fff transparent transparent transparent;
}

.popup-container .popup-bubble-anchor:has(> .clickable-info-window)::before {
  top: 100%;
  margin-left: -6px;
  border-color: #1BACE0 transparent transparent transparent;
}

.popup-container .info-content {
  margin: 0;
  font-size: 12px;
}

.popup-container .name-container,
.popup-container .vehicle-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.popup-container .partner-content {
  margin-top: 5px;
}

.name-content,
.vehicle-content,
.partner-content {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.copy-button {
  border-radius: 50%;
  border: none;
  width: 20px;
  height: 20px;
  padding: 0;
  cursor: pointer;
  background-color: white;
}

.copy-button:hover {
  filter: brightness(90%);
}

.icon-copy {
  height: 14px;
  margin-top: 2px;
}

.location-content {
  font-size: 12px;
  margin-top: 20px;
  margin-bottom: 40px;
}

.location-content p {
  margin: 5px 0px;
}

.action-content {
  position: absolute;
  bottom: 8px;
  width: 90%;
}

.action-btn {
  color: #1BACE0;
  cursor: pointer;
  font-size: 12px;
}