.item-container {
  .item-cancelled-container {
    background-image: url('../assets/img/cancelled-bg.svg');
    background-repeat: no-repeat;
    background-size: 352px 352px;
    background-position: center;
    display: block;
    height: 400px;
  }
  .item-transferred-info {
    display: flex;
    flex-direction: column;
    text-align: center;
    h4 {
      font-family: 'SFProDisplay-Bold';
      margin: 20px 10px;
    }
    p {
      font-family: 'SFProText-Light';
      margin: 0 10px;
    }
  }
  .item-top-info-container {
    .from-to-container {
      display: flex;
      flex-direction: row;
      overflow: hidden;
      justify-content: flex-end;
      margin-bottom: 54px;

      .from,
      .to {
        max-width: 150px;
        font-size: 16px;
        letter-spacing: -0.18px;
        font-family: 'SFProDisplay-Bold';

        & > div {
          height: 16px;
          font-size: 14px;
          font-weight: 300;
          letter-spacing: -0.15px;
          font-family: 'SFProText-Light';
        }
      }

      .markers {
        width: 90px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 0 8px;

        .marker {
          background-image: url('../assets/img/marker.svg');
          background-repeat: no-repeat;
          width: 24px;
          height: 24px;
        }

        .line {
          width: 42px;
          height: 0;
          margin: 20px 0 14px;
          opacity: 0.4;
          border-top: solid 1px #858f96;
        }
      }
    }
    .item-main-info-container {
      display: flex;
      flex-direction: row;
      margin-bottom: 17px;

      .item-tracking-info-container {
        .status-container {
          display: block;
          .status {
            display: inline-block;
            opacity: 0.87;
            font-size: 30px;
            font-weight: bold;
            line-height: 54px;
            letter-spacing: -0.6px;
            color: #000000;
            position: relative;
            font-family: 'SFProDisplay-Bold';

            &:after {
              content: '';
              position: absolute;
              display: block;
              width: 16px;
              height: 16px;
              background-color: #7ec937;
              border-radius: 50%;
              top: 4px;
              right: -17px;
            }

            &.in-transit {
              &:after {
                background-color: #ffaf21;
              }
            }

            &.processing {
              &:after {
                background-color: #fff357;
              }
            }

            &.out-for-pickup {
              &:after {
                background-color: #ffaf21;
              }
            }

            &.out-for-delivery {
              &:after {
                background-color: #ffaf21;
              }
            }

            &.cancelled {
              &:after {
                background-color: #e21838;
              }
            }
          }
        }

        .tracking-number-container {
          height: 20px;
          margin-bottom: 4px;
          font-size: 18px;
          color: #2f7ae2;
          letter-spacing: -0.32px;
          line-height: 1.25;
          font-family: 'SFProDisplay-Medium';

          .tracking-number {
            font-family: 'SFProDisplay-Bold';
          }
        }

        .eta-container {
          height: 19px;
          margin-top: 12px;
          font-size: 16px;
          font-weight: 500;
          color: #858f96;
          display: flex;
          align-items: center;
          font-family: 'SFProDisplay-Medium';

          &.healthy-eta {
            color: #499f68;

            .eta {
              color: #499f68;
            }
          }

          .eta {
            height: 19px;
            margin-left: 11px;
            font-size: 16px;
            font-weight: normal;
            font-style: italic;
            font-family: 'SFProDisplay-Regular';
          }

          .eta-information-icon {
            margin-left: 8px;
            cursor: pointer;
          }
        }
      }
    }
    .sender-info-container {
      margin-bottom: 16px;
      padding: 16px 16px 16px 63px;
      border-radius: 10px;
      background-color: #f7f8fa;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 8px;
        left: 16px;
        display: block;
        background-image: url('../assets/img/sender-info-icon.svg');
        background-repeat: no-repeat;
        background-size: 32px 32px;
        background-position: center;
        width: 32px;
        height: 32px;
      }

      &.one-line {
        &:before {
          top: 8px;
        }
      }

      .sender-name {
        height: 16px;
        font-family: 'SFProDisplay-Bold';
        font-size: 14px;
        font-weight: 600;
        line-height: 1.14;
      }

      .sender-name {
        height: 16px;
        font-family: 'SFProDisplay-Regular';
        font-size: 14px;
        line-height: 1.14;
      }
    }
  }
  .map-container {
    margin-bottom: 14px;
    height: 157px;
    border-radius: 5px;
    overflow: hidden;
  }
  .item-progress-container {
    .item-progress-status-container,
    .item-progress-point-container {
      position: relative;
      margin-top: 10px;
      font-size: 12px;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .step {
        position: relative;
        text-align: center;
        width: 25%;
        display: inline-block;
        color: #5e737d;
        font-weight: normal;
      }
    }
    .item-progress-status-container {
      .step {
        background-size: 56px 56px;
        background-repeat: no-repeat;
        padding: 62px 0 0 0;
        background-position: center top;
        font-family: 'SFProDisplay-Regular';
        font-size: 16px;
        color: #9b9b9b;

        &.active {
          font-family: 'SFProDisplay-Bold';
          color: #090e18;
        }
        &.status-processing {
          background-image: url('../assets/img/status-processing-active.svg');
        }

        &.status-out-for-pickup {
          background-image: url('../assets/img/status-out-for-pickup-inactive.png');
          &.active {
            background-image: url('../assets/img/status-out-for-pickup-active.svg');
          }
        }

        &.status-in-transit {
          background-image: url('../assets/img/status-in-transit-inactive.svg');
          &.active {
            background-image: url('../assets/img/status-in-transit-active.svg');
          }
        }

        &.status-out-for-delivery {
          background-image: url('../assets/img/status-out-for-delivery-inactive.svg');
          &.active {
            background-image: url('../assets/img/status-out-for-delivery-active.svg');
          }
        }

        &.status-delivered {
          background-image: url('../assets/img/status-delivered-inactive.svg');
          &.active {
            background-image: url('../assets/img/status-delivered-active.svg');
          }
        }
      }
    }
    .item-progress-point-container {
      .step {
        &:before {
          display: block;
          margin: 8px auto 4px;
          content: ' ';
          color: #ffffff;
          font-weight: bold;
          font-size: 12px;
          width: 18px;
          height: 18px;
          line-height: 20px;
          border-radius: 50%;
          background-image: url('../assets/img/status-inactive-point.svg');
          background-repeat: no-repeat;
          background-size: 100%;
          z-index: 2;
        }

        &:after {
          position: absolute;
          content: ' ';
          height: 2px;
          background-color: #dbdbdb;
          top: 16px;
          left: 54%;
          width: 92%;
          z-index: 0;
        }

        &:last-child::after {
          content: none;
        }

        &.active {
          color: #2d4d80;
          font-weight: bold;

          &:before {
            background-image: url('../assets/img/status-active-point.svg');
            width: 18px;
            height: 18px;
          }

          &:after {
            background-color: #2f7ae2;
            z-index: 0;
          }

          &.last {
            &:before {
              margin-top: 1px;
              background-image: url('../assets/img/status-last-active-point.svg');
              width: 32px;
              height: 32px;
            }

            &:after {
              background-color: #dbdbdb;
              width: calc(100% - 23px);
              left: 57.4%;
            }
          }
        }

        &.cancelled {
          color: #d0021b;
          &:before {
            border-color: #ffb6bf;
            background-color: #d0021b;
          }
        }
      }
    }
  }
  .item-log-container {
    margin: 48px 0;

    h3 {
      height: 29px;
      margin: 0 0 24px;
      font-size: 24px;
      font-weight: 500;
      letter-spacing: -0.26px;
      color: #000000;
      font-family: 'SFProDisplay-Medium';
    }

    table {
      border-collapse: collapse;
      width: 100%;

      thead {
        tr {
          th {
            height: 16px;
            font-size: 14px;
            font-weight: 600;
            color: #858f96;
            text-align: start;
            padding: 8px 0 8px 40px;
            border-bottom: 1px solid #d8d8d8;
            font-family: 'SFProDisplay-Semibold';

            &:first-child {
              padding-left: 0;
            }

            &.item-row-description {
              padding-left: 60px;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            height: 16px;
            font-size: 14px;
            color: #858f96;
            padding: 8px 0 8px 40px;
            vertical-align: top;
            font-family: 'SFProDisplay-Regular';

            &:first-child {
              white-space: nowrap;
              padding-left: 0;
            }

            &.item-row-description {
              width: 100%;
              font-family: 'SFProDisplay-Medium';
              padding-left: 60px;
              background-image: url('../assets/img/log-inactive.svg');
              background-size: 16px 16px;
              background-repeat: no-repeat;
              background-position: 22px 8px;
              position: relative;

              &:after {
                content: ' ';
                display: block;
                position: absolute;
                background-color: #dedede;
                height: calc(100% - 22px);
                left: 29.5px;
                width: 1px;
                top: 27px;
              }

              a {
                color: #2f7ae2;
                text-decoration: none;
              }
            }

            .images-container {
              display: flex;
              flex-direction: row;
              .image-container {
                cursor: pointer;

                img {
                  height: 85px;
                  margin-right: 8px;
                  border-radius: 10px;
                }
              }
            }
          }
          &.order-delivered-event-row {
            td {
              color: #000000;
              &.item-row-description {
                background-image: url('../assets/img/log-active.svg');

                div {
                  font-family: 'SFProDisplay-Medium';
                  margin-bottom: 4px;

                  &.bold {
                    font-family: 'SFProDisplay-Bold';
                  }
                  &.semi-bold {
                    font-family: 'SFProDisplay-Semibold';
                  }

                  &.light {
                    color: #858f96;
                  }

                  &:last-child {
                    margin-bottom: 0;
                  }
                }
              }
            }
          }

          &:last-child {
            td {
              &.item-row-description {
                &:after {
                  content: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

.images-slider-dialog {
  .body {
    padding: 20px;
    .image-container {
      max-width: 800px;
      img {
        width: 100%;
      }
    }
  }
}

.item-accordion {
  margin-bottom: 20px;

  & > .top {
    background-color: #d5e6ff;
    border-radius: 9px 9px 0 0;
    padding: 4px 24px;

    & > .head {
      display: flex;
      position: relative;
      width: 100%;
      height: 44px;
      color: #2f7ae2;
      flex-direction: row;
      align-items: center;

      .left-side {
        float: left;
        position: relative;
        height: 32px;
        line-height: 32px;
        display: flex;
        align-items: center;

        h4 {
          height: 20px;
          font-family: 'SFProText-Regular';
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.25;
          letter-spacing: -0.32px;
        }

        .item-tracking-number-container {
          display: inline-block;
          font-size: 16px;
          margin: 0 auto;

          label {
            display: inline-block;
            text-transform: uppercase;
            height: 32px;
            line-height: 32px;
            margin-left: 24px;
            padding-left: 24px;
            border-left: 3px solid #ffffff;
            font-family: 'SFProText-Medium';
          }

          span {
            text-transform: uppercase;

            &.item-tracking-number {
              font-weight: normal;
              font-family: 'SFProText-Bold';
            }
          }
        }
      }

      .right-side {
        float: right;
        position: relative;
        height: 32px;
        line-height: 32px;
        display: flex;
        align-items: center;
        flex: 1;
        justify-content: flex-end;

        .pod-link {
          display: inline-block;
          position: relative;
          padding-right: 9px;
          height: 16px;
          line-height: 16px;
          border-right: solid 1px #b7c0c5;
          margin-right: 9px;
          font-weight: 600;
          font-size: 10px;
          text-transform: uppercase;
          text-decoration: none;

          &:before {
            content: '';
            width: 14px;
            height: 10px;
            background-size: 100%;
            display: inline-block;
            margin-right: 3px;
            vertical-align: middle;
            background-repeat: no-repeat;
            background-image: url('../assets/img/eye-icon.svg');
          }
        }

        .toggle-item-details {
          display: inline-block;
          position: relative;
          font-size: 12px;
          font-weight: 600;
          line-height: 26px;
          cursor: pointer;
          margin-top: 10px;

          &:after {
            content: '';
            height: 44px;
            width: 44px;
            display: inline-block;
            background-size: 100%;
            background-repeat: no-repeat;
            background-image: url('../assets/img/accordion-arrow.svg');
          }
          &.collapse {
            &:after {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }

  & > .bottom {
    padding: 24px;
  }
}

@media only screen and (max-width: 959px) {
  .item-container {
    padding: 0 16px;
    .item-progress-container {
      margin-bottom: 24px;

      .step {
        font-size: 10px;
      }

      .item-progress-point-container {
        display: none;
      }

      .item-progress-status-container {
        .step {
          font-size: 13px;
          background-size: 48px 48px;
          padding-top: 56px;
        }
      }
    }

    .item-log-container {
      margin: 0;
      font-size: 11px;
    }

    .item-top-info-container {
      .from-to-container {
        margin-top: 16px;
        margin-bottom: 38px;
        justify-content: center;
      }

      .item-main-info-container {
        margin-bottom: 9px;

        .item-tracking-info-container {
          .tracking-number-container {
            font-size: 16px;
          }
        }
      }
    }
  }

  .item-accordion {
    & > .top {
      padding: 9px 0 7px 16px;
      & > .head {
        height: 36px;
        .left-side {
          height: 20px;
          line-height: 20px;

          h4 {
            text-overflow: ellipsis;
            overflow: visible;
            white-space: nowrap;
          }

          .item-tracking-number-container {
            text-overflow: ellipsis;
            overflow: visible;
            white-space: nowrap;
            label {
              margin-left: 8px;
              padding-left: 8px;
            }
          }
        }

        .right-side {
          height: 100%;
          line-height: 20px;
          font-size: 11px;
          position: absolute;
          right: 0;
          background: #d5e6ff;
          padding-right: 16px;

          .pod-link {
            display: none;
          }

          .toggle-item-details {
            font-size: 11px;
            margin-top: 6px;

            &:after {
              height: 32px;
              width: 32px;
            }
          }
        }
      }
    }

    & > .bottom {
      padding: 16px;
    }
  }
}
