.order-details-container {
  padding: 0 10px;

  .page-title {
    height: 33px;
    font-size: 24px;
    font-weight: bold;
    color: #3a484e;
    text-align: center;
    text-transform: uppercase;
    margin: 50px auto 45px;
  }

  .page-subtitle {
    margin-bottom: 16px;

    .tracking-number {
      font-size: 23px;
      font-weight: bold;
      color: #2f7ae2;

      .label {
        font-family: 'SFProText-Medium';
      }

      .value {
        font-family: 'SFProText-Bold';
      }
    }
    .invoice-link {
      float: right;
      // font-style: normal;

      a {
        font-size: 14px;
        color: #2d4d80;
        border: none;
        padding: 0;
        height: 19px;
        .invoice-icon {
          margin-right: 7px;
        }
      }
    }
  }

  .item-accordion {
    border-radius: 10px;
    box-shadow: 0 5px 15px 0 rgba(124, 133, 152, 0.15);
    border: solid 1px #dbdbdb;
  }
}

@media only screen and (max-width: 959px) {
  .order-details-container {
    padding: 0;

    .item-details-container {
      padding: 0;
    }

    .item-accordion {
      border-radius: 0;
      border-width: 0 0 1px 0;

      & > .top {
        border-radius: 0;
      }
    }

    .page-title {
      margin: 20px auto;
      font-size: 22px;
      height: 30px;
    }

    .page-subtitle {
      overflow: auto;
      margin-bottom: 12px;
      padding-left: 16px;
      .tracking-number {
        display: block;
        margin-bottom: 0;
      }
      .invoice-link {
        display: block;
        float: left;
        a {
          .invoice-icon {
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 959px) {
}
