.header-container {
  position: fixed;
  z-index: 900;
  width: 100%;
  background-color: #ffffff;
  top: 0;

  .header {
    height: 60px;
    width: 1050px;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .logo {
      background-size: contain;
      width: 130px;
      height: 40px;
      background-repeat: no-repeat;
    }
  }
}

@media only screen and (max-width: 959px) {
  .header-container {
    .header {
      width: calc(100% - 30px);
      height: 60px;

      .logo {
        width: 87px;
        height: 40px;
      }
    }
  }
}
